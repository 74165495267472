import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ContentTabs from 'layouts/ContentTabs';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AbilityContext } from 'utils/Can';
import { MODS } from 'utils/structure';
import Save from 'views/People/Save';
import Clients from './Clients';
import Passengers from './Passengers';

export default function TabMenu() {
  const [tabs, setTabs] = useState([]);
  const ability = useContext(AbilityContext);

  // const currentPath = goto(MODS.PASSENGERS);
  const { path } = useRouteMatch();

  useEffect(() => {
    const pat = [];
    if (ability.can('manage_clients', MODS.PASSENGERS)) {
      pat.push(
        ...[
          {
            title: 'PAX PRINCIPALES',
            icon: <EmojiPeopleIcon />,
            route: '/clientes',
            get content() {
              return (
                <Switch>
                  <Route path={`${path + this.route}/nuevo`}>
                    <Save />
                  </Route>
                  <Route path={`${path + this.route}/:id`}>
                    <Save />
                  </Route>
                  <Route path={path + this.route}>
                    <Clients path={path + this.route} />
                  </Route>
                </Switch>
              );
            },
          },
        ],
      );
    }
    if (ability.can('manage_passengers', MODS.PASSENGERS)) {
      pat.push(
        ...[
          {
            title: 'PAX SECUNDARIOS',
            icon: <DirectionsWalkIcon />,
            route: '/secundarios',
            get content() {
              return (
                <Switch>
                  <Route path={`${path + this.route}/nuevo`}>
                    <Save />
                  </Route>
                  <Route path={`${path + this.route}/:id`}>
                    <Save />
                  </Route>
                  <Route path={path + this.route}>
                    <Passengers />
                  </Route>
                </Switch>
              );
            },
          },
        ],
      );
    }
    if (pat.length) {
      setTabs(pat);
    }
  }, []);

  return <ContentTabs tabs={tabs} defaultIndex={0} />;
}
