import axios from 'axios';
import { Cookies } from 'react-cookie';
import { baseURL } from '../config';
// import { useHistory } from 'react-router-dom';

const cookies = new Cookies();
const API = {
  init() {
    Object.entries(API.paths).map(([key, value]) => {
      API[key] = API.actions(value);
      return null;
    });
  },
  instance: () => {
    const instance = axios.create({
      baseURL,
      headers: {
        ...(cookies.get('access') ? { Authorization: `Bearer ${cookies.get('access')}` } : {}),
      },
    });

    instance.interceptors.response.use(
      response => response,
      async error => {
        // ! No se muy bien como tratar
        if (error?.response?.status !== 401) {
          return Promise.reject(error);
        }

        if (!cookies.get('access') || !cookies.get('refresh')) {
          API.authDestroy();
          if (window.location.pathname.indexOf('/login') === -1) {
            window.location = '/login';
          }
          return Promise.reject(error);
        }

        try {
          const {
            data: { access },
          } = await axios.post(`${baseURL}/refresh/`, { refresh: cookies.get('refresh') });
          cookies.set('access', access, { path: '/' });
          return axios({
            ...error.config,
            headers: {
              Authorization: `Bearer ${access}`,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          });
        } catch (e) {
          API.authDestroy();
          if (window.location.pathname.indexOf('/login') === -1) {
            window.location = '/login';
          }
          return Promise.reject(error);
        }
      },
    );

    return instance;
  },
  paths: {
    ServiceOrder: '/serviceorders/',
    Origin: '/origins/',
    ServiceRequest: '/servicerequests/',
    Package: '/packages/',
    Country: '/countries/',
    Region: '/regions/',
    City: '/cities/',
    Reservation: '/reservations/',
    PaymentVoucher: '/paymentvouchers/',
    PackageGroup: '/packagesgroups/',
    PaymentMethod: '/paymentmethods/',
    PromotionPost: '/promotionsposts/',
    Configuration: '/configurations/',

    Language: '/languages/',
    Person: '/persons/',
    PersonType: '/persontypes/',
    SupplierType: '/suppliertypes/',
    Supplier: '/suppliers/',
    Service: '/services/',
    Client: '/clients/',
    ServiceOrderPayment: '/serviceorderpayments/',
    Contact: '/contacts/',
    Responsible: '/responsibles/',
    User: '/users/',
    Passenger: '/passengers/',
    Book: '/books/',
    CustomMeta: '/custommetas/',
    CustomField: '/customfields/',
    ReservationPayment: '/reservationspayments/',
  },
  actions: path => ({
    all(params = { get_all: true }) {
      return API.instance().get(path, { params });
    },
    action(uri, params = {}) {
      return API.instance().get(`${path + uri}/`, { params });
    },
    post(id, uri, params = {}) {
      return API.instance().post(`${`${path + id}/${uri}`}/`, { params });
    },
    get(id, params = {}) {
      return API.instance().get(`${path + id}/`, { params });
    },
    create(query = {}) {
      return API.instance().post(path, query);
    },
    update(id, query = {}) {
      return API.instance().put(`${path + id}/`, query);
    },
    pdf(query = {}) {
      return `${baseURL}${`${path}pdf`}/?${Object.keys(query)
        .map(key => `${key}=${query[key]}`)
        .join('&')}`;
    },
    remove(id, query = {}) {
      return API.instance().delete(`${path + id}/`, query);
    },
  }),
  isAuthenticated() {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const valid = user ? user.is_active : false;
    return !!cookies.get('access') && valid;
  },
  authenticate(username, password) {
    API.authDestroy();
    return new Promise((resolve, error) => {
      axios
        .post(`${baseURL}/access/`, {
          username,
          password,
        })
        .then(({ data }) => {
          cookies.set('access', data.access, { path: '/' });
          cookies.set('refresh', data.refresh, { path: '/' });
          // setTimeout(() => {
          resolve(true);
          // }, 500);
        })
        .catch(({ response }) => {
          error(response);
        });
    });
  },
  authDestroy() {
    cookies.remove('access', { path: '/' });
    cookies.remove('refresh', { path: '/' });
    localStorage.removeItem('user');
  },
};

API.init();

export { API };
export default API;
