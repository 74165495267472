/* eslint-disable no-template-curly-in-string */
import { blue as primary } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';

export const palette = createPalette({
  primary,
  secondary: {
    main: '#5e35b1',
  },
});

export const theme = createTheme({
  palette,
  overrides: {
    MuiTable: {
      root: {
        width: 'max-content',
        margin: 'auto',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: '#fff !important',
      },
      icon: {
        color: '#fff !important',
      },
    },
    MuiTableCell: {
      head: {
        width: 'auto !important',
        // '&:last-child svg': {
        //   display: 'none !important'
        // },
        color: '#FFF',
      },
      root: {
        padding: '0 4px !important', // Icon es mediano
        '& a': {
          color: '#0277bd',
        },
        '& a:hover': {
          textDecoration: 'underline',
        },
        width: 'auto !important',
        fontSize: '0.7rem',
      },
      body: {
        // '&:last-child': {
        //   textAlign: 'center'
        // },
        // '&:last-child div': {
        //   display: 'block !important'
        // },
        fontSize: '0.7rem !important',
        backgroundColor: '#FFFFFF',
      },
    },
    MuiTableRow: {
      root: {
        height: 'auto !important',
        '& .MuiTableCell-head': {
          backgroundColor: '#01579b',
          padding: '5px',
        },
      },
    },
    MuiToolbar: {
      root: {
        paddingLeft: '0 !important',
      },
      gutters: {
        paddingLeft: '0 !important',
      },
    },
    MuiTextField: {
      root: {
        paddingLeft: '0 !important',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: palette.primary.main,
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        height: '38px',
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    MuiDialog: {
      paper: {
        overflowY: 'visible',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0 2px',
        fontSize: '1.2rem',
      },
    },
    MuiIcon: {
      root: {
        fontSize: '1.2rem',
      },
    },
  },
});

export const localization = {
  body: {
    emptyDataSourceMessage: '---',
    addTooltip: 'Agregar',
    deleteTooltip: 'Eliminar',
    editTooltip: 'Editar',
    editRow: {
      deleteText: '¿Está seguro que desea eliminar esta fila?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Guardar',
    },
  },
  header: {
    actions: 'Acciones ',
  },
  pagination: {
    labelRowsPerPage: 'Filas por página',
    labelRowsSelect: 'Filas Mostradas',
    labelDisplayedRows: '{count} resultados',
    firstAriaLabel: 'Primera página',
    firstTooltip: 'Primera página',
    previousAriaLabel: 'Anterior página',
    previousTooltip: 'Anterior página',
    nextAriaLabel: 'Siguiente página',
    nextTooltip: 'Siguiente página',
    lastAriaLabel: 'Última página',
    lastTooltip: 'Última página',
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
    nRowsSelected: '{0} seleccionado(s)',
    showColumnsTitle: 'Mostrar columnas',
    showColumnsAriaLabel: 'Mostrar columnas',
    exportTitle: 'Exportar',
    exportAriaLabel: 'Exportar',
    exportCSVName: 'Exportar a Excel CSV',
    exportPDFName: 'Exportar a PDF',
    addRemoveColumns: 'Mostrar u ocultar columnas',
  },
};

export const yuplocale = {
  mixed: {
    default: 'No es valido',
  },
  number: {
    min: 'Deve ser maior que ${min}',
    max: 'Deve ser maior que ${max}',
  },
  typeError: {
    default: 'Ocurrió un error',
  },
};

//export const baseURL = 'https://sky-back.tecno-craft.com';
export const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://sky-back.tecno-craft.com'
    : `http://${window.location.hostname}:8000`;

export const ROLE = {
  ADMIN: 3,
  ACCOUNTING: 1,
  COUNTER: 2,
  SUPER: 4,
  SUPER_COUNTER: 5,
  OPERATION_COUNTER: 6,
};

export const PERSON_TYPE = {
  CLIENT: 1,
  PASSENGER: 3,
  CONTACT: 2,
  RESPONSIBLE: 4,
};

export const symbolCurrency = {
  SOLES: 'S/ ',
  DOLARES: '$ ',
  EUROS: '€ ',
};

export const ageGroupOptions = {
  ADULT: 'ADULTO',
  CHILD: 'NIÑO',
  INFANT: 'INFANTE',
};

export const genderOptions = {
  M: 'MASCULINO',
  F: 'FEMENINO',
  S: 'SIN ESPECIFICAR',
};

// export const comingOptions = [{
//   id: 'AIR',
//   name: 'TRANSPORTE AEREO',
//   detail_label: 'Datos de vuelo'
// },
// {
//   id: 'GROUND',
//   name: 'TRANSPORTE TERRESTRE',
//   detail_label: 'Datos de transporte'
// },
// {
//   id: 'OTHER',
//   name: 'OTRO MEDIO',
//   detail_label: 'Especificar'
// }];

export const comingOptions = {
  AIR: {
    name: 'TRANSPORTE AEREO',
    detail_label: 'Datos de vuelo',
  },
  GROUND: {
    name: 'TRANSPORTE TERRESTRE',
    detail_label: 'Datos de transporte',
  },
  OTHER: {
    name: 'OTRO MEDIO',
    detail_label: 'Especificar',
  },
};

export const modeServiceOptions = {
  PRIVATE: 'PRIVADO',
  POOL: 'POOL',
};

export const comingFromOptions = {
  FOREING: 'Extranjeros',
  NATIONAL: 'Nacionales',
};

export const currencyOptions = {
  SOLES: 'SOLES',
  DOLARES: 'DOLARES',
  EUROS: 'EUROS',
};

export const voucherToOptions = {
  CLIENT: 'PAX PRINCIPAL',
  PASSENGER: 'ACOMPAÑANTE',
  ORIGIN: 'PROCEDENCIA',
  ORIGINPERSON: 'RESPONSABLE',
  ENTITY: 'ENTIDAD',
  NEW: 'OTRA PERSONA',
};

export const VOUCHER_CODE = {
  FACTURA: 1,
  BOLETA: 2,
};
