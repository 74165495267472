import { Box, Button, Grid, makeStyles, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import SaveIcon from '@material-ui/icons/Save';
import GridItem from 'components/Grid/GridItem';
import HeaderTitle from 'components/HeaderTitle';
import OptionsViewer from 'components/OptionsViewer';
import Viewer from 'components/Viewer';
import { symbolCurrency } from 'config';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import React, { useContext, useEffect, useLayoutEffect, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from 'utils/api';
import { AbilityContext } from 'utils/Can';
import { useEditPage, useLocalStorage } from 'utils/hooks';
import { goto, MODS } from 'utils/structure';
import Toast from 'utils/toast';
import Payments from './Payments/Index';

const useStyles = makeStyles(() => ({
  root: {
    // width: '100%',
  },
  gridItem: {
    padding: '0 15px',
    margin: '0 0 20px',
  },
  button: {
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
    },
    '&:visited': {
      color: '#FFF',
    },
  },
  caja: {
    '&:hover legend': {
      display: 'none',
    },
    '&:hover div': {
      display: 'flex',
    },
  },
}));

function reducer(state, action) {
  switch (action.type) {
    case 'CALC_SOLES':
      return {
        ...state,
        soles: action.services.filter(i => i.currency === 'SOLES').reduce((a, i) => a + parseFloat(i.price), 0),
      };
    case 'CALC_DOLARES':
      return {
        ...state,
        dolares: action.services.filter(i => i.currency === 'DOLARES').reduce((a, i) => a + parseFloat(i.price), 0),
      };
    case 'CALC_EUROS':
      return {
        ...state,
        euros: action.services.filter(i => i.currency === 'EUROS').reduce((a, i) => a + parseFloat(i.price), 0),
      };
    default:
      return state;
  }
}

export default function View() {
  const classes = useStyles();

  const [reservation, id, info] = useEditPage(id => API.Reservation.get(id));

  const history = useHistory();

  const ability = useContext(AbilityContext);

  const [user] = useLocalStorage('user');

  const [total, dispatch] = React.useReducer(reducer, {
    soles: 0,
    dolares: 0,
    euros: 0,
  });

  const [checked, setChecked] = React.useState(false);
  const [accessCode, setAccessCode] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const toggleChecked = async () => {
    setLoading(true);
    if (!checked) {
      setChecked(true);
      try {
        const {
          data: { access },
        } = await API.Reservation.action('makecode', { activate: id });
        if (access) setAccessCode(access);
      } catch {
        setChecked(false);
      }
    } else {
      setChecked(false);
      try {
        const {
          data: { remove },
        } = await API.Reservation.action('makecode', { remove: id });
        if (remove) setAccessCode(null);
      } catch {
        setChecked(true);
      }
    }
    setLoading(false);
  };

  function cpText() {
    const copyText = document.getElementById('accessCode');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }
  function cpLink() {
    const accessLink = document.getElementById('accessLink');
    accessLink.select();
    accessLink.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  async function sendCode(email) {
    setLoading(true);
    try {
      const {
        data: { success },
      } = await API.Reservation.action('sendcode', {
        r: id,
        e: email.toLowerCase(),
      });
      if (success === 'ok') {
        window.alert('¡Correo enviado!'); // eslint-disable-line no-alert
      } else {
        window.alert('Correo incorrecto.'); // eslint-disable-line no-alert
      }
    } catch {
      window.alert('Correo incorrecto o Falla en conexión'); // eslint-disable-line no-alert
    }
    setLoading(false);
  }

  useEffect(() => {
    if (reservation?.reservation_details.length) {
      dispatch({
        type: 'CALC_SOLES',
        services: reservation.reservation_details,
      });
      dispatch({
        type: 'CALC_DOLARES',
        services: reservation.reservation_details,
      });
      dispatch({
        type: 'CALC_EUROS',
        services: reservation.reservation_details,
      });
    }
    if (reservation?.reference_code) {
      setChecked(true);
      setAccessCode(reservation?.reference_code);
    }
  }, [reservation]);

  const { hash } = useLocation();

  useLayoutEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(hash.replace('#', ''));
      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop : 0,
      });
    }, 200);
  }, [hash]);

  if (info.is404) {
    return <div className={classes.root}>No existe la página.</div>;
  }

  if (info.isLoading) {
    return <div className={classes.root}>Cargando...</div>;
  }

  return (
    <Paper>
      <HeaderTitle title={`RESERVA ${reservation.code}`} />
      {ability.can('edit', 'app') && (
        <Box
          p={2}
          position="absolute"
          bgcolor={accessCode ? '#cddc39' : '#9e9e9e'}
          right={30}
          width="16rem"
          height="120px"
          className={accessCode ? classes.caja : null}
        >
          {accessCode && (
            <>
              <Box component="div" display="none" color="#000" textAlign="center">
                <input
                  id="accessCode"
                  defaultValue={accessCode}
                  style={{
                    fontSize: '1.5rem',
                    width: '100%',
                    pointerEvents: 'none',
                  }}
                  spellCheck="false"
                  tabIndex="-1"
                />
                <button type="button" style={{ marginLeft: '-60px', width: '60px' }} onClick={cpText}>
                  Copiar
                </button>
              </Box>

              <Box component="div" display="none" color="#000" pt={1} textAlign="center">
                <input
                  id="mail"
                  spellCheck="false"
                  defaultValue={reservation.email_client.toLowerCase()}
                  placeholder="Correo para recibir código"
                  style={{
                    fontSize: '0.8rem',
                    width: '100%',
                  }}
                  tabIndex="-1"
                />
                <button
                  type="button"
                  style={{ marginLeft: '-60px', width: '60px' }}
                  onClick={() => sendCode(document.getElementById('mail').value)}
                  disabled={loading}
                >
                  Enviar
                </button>
              </Box>
              <Box component="div" display="none" color="#000" pt={1} textAlign="center">
                <input
                  id="accessLink"
                  defaultValue={`https://app.skyperu.com/#${accessCode}`}
                  style={{
                    fontSize: '0.75rem',
                    width: '100%',
                    pointerEvents: 'none',
                  }}
                  spellCheck="false"
                  tabIndex="-1"
                />
                <button type="button" style={{ marginLeft: '-60px', width: '60px' }} onClick={cpLink}>
                  Copiar
                </button>
              </Box>
            </>
          )}
          <FormLabel
            component="legend"
            style={{
              textAlign: 'center',
              color: accessCode ? '#000' : '#EEE',
            }}
          >
            · Código de acceso APP
          </FormLabel>
          <div
            style={{
              position: 'absolute',
              bottom: '5px',
              width: 'inherit',
              justifyContent: 'center',
              // display: 'grid'
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Switch checked={checked} onChange={toggleChecked} />

            {loading ? (
              <Box>Cargando...</Box>
            ) : (
              <>{checked ? <Box>Desactivar</Box> : <Box color="#EEE">Activar</Box>}</>
            )}
          </div>
        </Box>
      )}
      <Box display="flex" p={1} alignItems="baseline">
        <Typography color="primary">({reservation.total_num_pax}) PASAJEROS </Typography>
        <small style={{ marginLeft: '10px' }}>({reservation.total_num_pax_format})</small>
      </Box>
      <Divider />
      <Box p={2}>
        <Viewer name="Person" value={reservation.client}>
          {({ name }) => (
            <li>
              <strong>{name}</strong>
            </li>
          )}
        </Viewer>
        {reservation.passengers.map(p => (
          <Viewer name="Person" key={`passenger${p}`} value={p}>
            {({ name }) => <li>{name}</li>}
          </Viewer>
        ))}
      </Box>
      <Box p={1}>
        <Typography color="primary" display="block" variant="body1">
          PROCEDENCIA
        </Typography>
      </Box>
      <Divider />
      <Box display="flex">
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>AGENCIA/PROCEDENCIA</small>
          <Viewer name="Origin" value={reservation.origin}>
            {({ name }) => <span>{name}</span>}
          </Viewer>
        </Box>
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>CONTACTO</small>
          <Viewer name="Person" value={reservation.responsible}>
            {({ name }) => <span>{name}</span>}
          </Viewer>
        </Box>
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>DETALLES</small>
          <span>{reservation.origin_details || '-'}</span>
        </Box>
      </Box>
      <Box p={1}>
        <Typography color="primary" display="block" variant="body1">
          LLEGADA Y SALIDA
        </Typography>
      </Box>
      <Divider />
      <Box display="flex">
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>LLEGADA</small>
          <span>{format(parseISO(reservation.arrival_date), 'P', { locale: es })}</span>
        </Box>
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>VIA</small>
          <OptionsViewer name="comingOptions" value={reservation.arrival_via}>
            {({ name }) => <span>{name}</span>}
          </OptionsViewer>
        </Box>
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>DETALLES</small>
          <span>{reservation.arrival_detail || '-'}</span>
        </Box>
      </Box>
      <Box display="flex">
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>SALIDA</small>
          <span>{format(parseISO(reservation.departure_date), 'P', { locale: es })}</span>
        </Box>
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>VIA</small>
          <OptionsViewer name="comingOptions" value={reservation.departure_via}>
            {({ name }) => <span>{name}</span>}
          </OptionsViewer>
        </Box>
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>DETALLES</small>
          <span>{reservation.departure_detail || '-'}</span>
        </Box>
      </Box>

      <Box p={1}>
        <Typography color="primary" display="block" variant="body1">
          TOURS
        </Typography>
      </Box>
      <Divider />

      {reservation.reservation_details.map(deta => (
        <Box key={`detail${deta.id}`}>
          <Box bgcolor="primary.dark" color="#FFF" paddingX={2} paddingY={1}>
            <Viewer name="Package" value={deta.package}>
              {({ name }) => <span>{name}</span>}
            </Viewer>
          </Box>
          <Box display="flex">
            <Box p={2} width={1 / 4} display="flex" flexDirection="column">
              <small>MODO</small>
              <OptionsViewer name="modeServiceOptions" value={deta.mode}>
                {option => <span>{option}</span>}
              </OptionsViewer>
            </Box>
            <Box p={2} width={1 / 4} display="flex" flexDirection="column">
              <small>Fecha/Hora de inicio</small>
              <span>{format(parseISO(deta.start_date), 'Pp', { locale: es })}</span>
            </Box>
            <Box p={2} width={1 / 4} display="flex" flexDirection="column">
              <small>Fecha/Hora de finalización</small>
              <span>{format(parseISO(deta.comple_date), 'Pp', { locale: es })}</span>
            </Box>
            <Box p={2} width={1 / 4} display="flex" flexDirection="column">
              <small>Precio</small>
              <span>{`${deta.price} ${deta.currency}`}</span>
            </Box>
          </Box>

          <Box p={2}>
            <small>Detalles:</small>
            {deta.service_requests.map(sr => (
              <Viewer key={`sr${sr.id}`} name="Service" value={sr.service}>
                {service => (
                  <li>
                    <strong>{sr.quantity}</strong>
                    {' servicios de '}
                    <strong>{`"${service.supplier_name}/${service.description}"`}</strong>
                    {` para el ${sr.date_in ? format(parseISO(sr.date_in), 'PPPP', { locale: es }) : '---'}`}
                    {!!sr.time_in &&
                      ` a las ${format(parseISO(`2000-01-01T${sr.time_in}`), 'hh:mm a', { locale: es })}`}
                    {!!sr.date_out &&
                      ` hasta el ${format(parseISO(sr.date_out), 'PPPP', {
                        locale: es,
                      })}`}
                  </li>
                )}
              </Viewer>
            ))}
          </Box>
        </Box>
      ))}

      <Box paddingX={2} paddingY={1}>
        <Paper
          square
          elevation={3}
          style={{
            backgroundColor: '#CDDC39',
            fontWeight: 'bold',
            marginTop: '5px',
          }}
        >
          <Grid container justify="flex-start">
            {!!total.soles && (
              <>
                <GridItem md={2}>
                  <small>TOTAL SOLES</small>
                </GridItem>
                <GridItem md={2}>{`${symbolCurrency.SOLES}${total.soles.toFixed(2)}`}</GridItem>
              </>
            )}
            {!!total.dolares && (
              <>
                <GridItem md={2}>
                  <small>TOTAL DOLARES</small>
                </GridItem>
                <GridItem md={2}>{symbolCurrency.DOLARES + total.dolares.toFixed(2)}</GridItem>
              </>
            )}
            {!!total.euros && (
              <>
                <GridItem md={2}>
                  <small>TOTAL EUROS</small>
                </GridItem>
                <GridItem md={2}>{symbolCurrency.EUROS + total.euros.toFixed(2)}</GridItem>
              </>
            )}
          </Grid>
        </Paper>
      </Box>

      <Box p={1}>
        <Typography color="primary" display="block" variant="body1">
          INFORMACIÓN GENERAL
        </Typography>
      </Box>
      <Divider />
      <Box display="flex">
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>PUNTO DE RECOJO:</small>
          <span>
            {reservation.pick_up_point.split('\n').map((item, key) => (
              <Fragment key={`pick_${String(key)}`}>
                {item}
                <br />
              </Fragment>
            )) || '---'}
          </span>
        </Box>
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>ORIGEN DEL GRUPO:</small>
          <OptionsViewer name="comingFromOptions" value={reservation.coming_from}>
            {option => <span>{option}</span>}
          </OptionsViewer>
        </Box>
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>Idioma:</small>
          <span>{reservation.language_name}</span>
        </Box>
      </Box>

      <Box display="flex">
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>OBSERVACIONES DE LA RESERVA</small>
          <span>
            {reservation.observations.split('\n').map((item, key) => (
              <Fragment key={`observations_${String(key)}`}>
                {item}
                <br />
              </Fragment>
            )) || '---'}
          </span>
        </Box>
        <Box p={2} width={1 / 3} display="flex" flexDirection="column">
          <small>INDICACIONES PARA CLIENTE</small>
          <span>
            {reservation.specialreqs.split('\n').map((item, key) => (
              <Fragment key={`specialreqs_${String(key)}`}>
                {item}
                <br />
              </Fragment>
            )) || '---'}
          </span>
        </Box>
      </Box>

      <Box>
        <Payments readonly />
      </Box>

      <Box p={2} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="default"
          // size="large"
          startIcon={<SaveIcon />}
          target="_blank"
          href={API.Reservation.pdf({
            reservation: id,
            r: user.id,
          })}
          // className={classes.button}
        >
          {`Reporte Pasajero ${reservation.code}`}
        </Button>
        <div style={{ width: '20px' }} />
        <Button
          variant="contained"
          color="secondary"
          // size="large"
          startIcon={<SaveIcon />}
          target="_blank"
          href={API.Reservation.pdf({
            reservation: id,
            full: 1,
            r: user.id,
          })}
          className={classes.button}
        >
          {`Reporte Full ${reservation.code}`}
        </Button>
        <div style={{ width: '100px' }} />
        {reservation.approve !== 'CANCELED' && ability.can('approve', 'reservation_button_approve') && (
          <>
            <div id="approve">
              <Button
                variant="outlined"
                color="secondary"
                onClick={async () => {
                  const { isConfirmed } = await Swal.fire({
                    title: `¿Anular ${reservation.code || 'el registro'}?`,
                    text: 'Se anulará la reserva.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Anular',
                    cancelButtonText: 'Mantener',
                  });

                  if (isConfirmed) {
                    API.Reservation.update(id, { approve: 'CANCELED' }).then(() => {
                      Toast.fire({
                        icon: 'info',
                        title: `Reserva ${reservation.code} anulada.`,
                      });
                      history.push(goto(MODS.RESERVATIONS));
                    });
                  }
                }}
                // size="large"
                startIcon={<DesktopAccessDisabledIcon />}
                id="anular"
              >
                ANULAR RESERVA
              </Button>
            </div>
            <div style={{ width: '20px' }} />
          </>
        )}
        {reservation.approve === 'CANCELED' && ability.can('approve', 'reservation_button_approve_revert') && (
          <>
            <div id="approve">
              <Button
                variant="outlined"
                color="primary"
                onClick={async () => {
                  const { isConfirmed } = await Swal.fire({
                    title: `¿Aprobar ${reservation.code || 'el registro'}?`,
                    text: 'Se revertirá la anulación.',
                    icon: 'warning',
                    showCancelButton: true,
                    // confirmButtonColor: "#d33",
                    // cancelButtonColor: "#3085d6",
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                  });

                  if (isConfirmed) {
                    API.Reservation.update(id, { approve: 'CONFIRMED' }).then(() => {
                      Toast.fire({
                        icon: 'info',
                        title: `Reserva ${reservation.code} aprobada.`,
                      });
                      history.push(goto(MODS.RESERVATIONS));
                    });
                  }
                }}
                // size="large"
                // startIcon={<DesktopAccessDisabledIcon />}
                id="anular"
              >
                REVERTIR ANULACIÓN
              </Button>
            </div>
            <div style={{ width: '20px' }} />
          </>
        )}
        {ability.can('delete', 'reservation') && (
          <>
            <Button
              variant="contained"
              color="default"
              onClick={() => {
                Swal.fire({
                  title: `¿Eliminar ${reservation.code || 'el registro'}?`,
                  text: 'Se eliminará completamente.',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: '¡Eliminar!',
                  cancelButtonText: 'Mantener.',
                }).then(result => {
                  if (result.value) {
                    API.Reservation.remove(id).then(() => {
                      Toast.fire({
                        icon: 'info',
                        title: 'El registro fue eliminado.',
                      });
                      history.push(goto(MODS.RESERVATIONS));
                    });
                  }
                });
              }}
              startIcon={<DeleteForeverIcon />}
            >
              ELIMINAR RESERVA
            </Button>
          </>
        )}
      </Box>
    </Paper>
  );
}
